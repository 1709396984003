import React from "react";
import { Input } from "reactstrap";

const JobSearch = ({ value, onChange }) => {
  return (
    <React.Fragment>
      <Input
        type="search"
        id="job-title"
        className="form-control filter-input-box"
        placeholder="Search by Regulation Title Keyword ..."
        value={value} // Set value from parent state
        onChange={onChange} // Call onChange function passed as prop
      />
    </React.Fragment>
  );
};

export default JobSearch;
