import React, { useState, useEffect } from "react";
import Link from "Utils/LinkWrapper";
import { CardBody, Col, Row } from "reactstrap";
import Skeleton from 'react-loading-skeleton'; // Import Skeleton library for loading badges
import ReactPaginate from "react-paginate";
import { countryName, isEU } from "Utils/Utils";
import fetchEnc from "Utils/FetchEnc";
import ExpandText from "Utils/ExpandText";

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const elementRect = element.getBoundingClientRect();
    const elementOffset = elementRect.top + window.scrollY;
    const offset = window.innerHeight / 2 - elementRect.height / 2;

    window.scrollTo({
      top: elementOffset - offset,
      behavior: 'smooth'
    });

    const originalStyle = { ...element.style };
    element.style.transition = 'background-color 0.5s';
    element.style.backgroundColor = 'rgba(189, 186, 231, 0.5)';
    const timeout = setTimeout(() => {
      element.style.transition = 'background-color 0.5s';
      element.style.backgroundColor = originalStyle.backgroundColor || '';
    }, 1000);
    const originalOnClick = element.onclick;
    element.onclick = () => {
      clearTimeout(timeout);
      element.onclick = originalOnClick;
      element.style.backgroundColor = originalStyle.backgroundColor || '';
    };
  }
};

const CandidateDetails = ({ candidates, metadata, category }) => {
  const [sortOrder, setSortOrder] = useState("default");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [availability, setAvailability] = useState({}); // Track fetched availability
  const [loadingCandidates, setLoadingCandidates] = useState(new Set()); // Track currently fetching candidates

  const uniqueCandidates = [];
  const candidateMap = new Map();

  candidates.forEach(candidate => {
    const accuracy = parseFloat(candidate.accuracyRating?.replace(/%/g, '')) || 0;
    if (!candidateMap.has(candidate.code) || candidateMap.get(candidate.code).accuracy < accuracy) {
      candidateMap.set(candidate.code, { ...candidate, accuracy });
    }
  });

  candidateMap.forEach(value => uniqueCandidates.push(value));

  const sortedCandidates = uniqueCandidates.sort((a, b) => {
    const aScore = a.accuracy;
    const bScore = b.accuracy;

    if (sortOrder === "ne") {
      return bScore - aScore; // Most Accurate
    } else if (sortOrder === "od") {
      return aScore - bScore; // Least Accurate
    }
    return bScore - aScore; // Most Accurate
  });

  // Pagination logic
  let pageCount;
  const indexOfLastCandidate = (currentPage + 1) * itemsPerPage; // Last index of current page
  const indexOfFirstCandidate = indexOfLastCandidate - itemsPerPage;
  const currentCandidates = metadata?.page === "all" ? sortedCandidates : sortedCandidates.slice(indexOfFirstCandidate, indexOfLastCandidate);

  if (metadata?.page === "all") {
    pageCount = 1;
  } else {
    pageCount = Math.ceil(sortedCandidates.length / itemsPerPage);
  }

  // Function to fetchEnc availability data for a candidate
  const fetchAvailability = async (candidate) => {
    const product = candidate.code;
    const destination = metadata.destination;
    const origin = metadata.origin;
    let options = {
      userEmail: "availability@smarths.com",
      userSub: "availability",
    };

    try {
      // Add to loading set to prevent duplicate fetches
      setLoadingCandidates(prev => new Set(prev).add(candidate.code));

      const qrUrl = `https://mtech-api.com/web-app/import-export-restrictions?product=${product}&reporter=${destination}`;
      const antiDumpingUrl = `https://mtech-api.com/web-app/duty-measures?reporter=${destination}&exporter=${origin}&product=${product}`;
      const prUrl = `https://mtech-api.com/web-app/product-requirements?product=${product}&country=${destination}`;
      const importLicensingUrl = `https://mtech-api.com/web-app/import-licensing/country-regulations?country=${destination}&product=${product}`;

      const requests = [
        fetchEnc(qrUrl, options).then(res => res.json()).catch(() => null),
        fetchEnc(antiDumpingUrl, options).then(res => res.json()).catch(() => null),
        fetchEnc(prUrl, options).then(res => res.json()).catch(() => null),
        fetchEnc(importLicensingUrl, options).then(res => res.json()).catch(() => null),
      ];

      if (isEU(destination)) {
        requests.push(fetchEnc(`https://mtech-api.com/web-app/eu-taric?product=${product}&country=${origin}`, options).then(res => res.json()).catch(() => null));
      }

      const [qrData, antiDumpingData, prData, importLicensingData, euTaricData] = await Promise.all(requests);

      // Update availability state for this candidate
      setAvailability(prev => ({
        ...prev,
        [candidate.code]: {
          importDuty: true,
          qr: qrData?.data?.length > 0 ? true : false,
          antiDumping: Object.values(antiDumpingData || {}).some(arr => arr.length > 0) ? true : false,
          pr: prData?.ProductRequirementsDetails?.length > 0 ? true : false,
          importLicensing: importLicensingData?.data?.length > 0 ? true : false,
          euTaric: euTaricData?.length > 0 ? true : false,
        },
      }));
    } catch (error) {
      console.error("Error fetching availability data: ", error);
    } finally {
      // Remove from loading set when done
      setLoadingCandidates(prev => {
        const newSet = new Set(prev);
        newSet.delete(candidate.code);
        return newSet;
      });
    }
  };

  // Fetch availability for visible candidates when the page loads
  useEffect(() => {
    currentCandidates.forEach((candidate) => {
      if (!availability[candidate.code] && !loadingCandidates.has(candidate.code)) {
        fetchAvailability(candidate); // Only fetchEnc if not already fetched and not currently fetching
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCandidates, availability, loadingCandidates]);



  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Update current page
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col lg={8}>
          <div className="mb-3 mt-3 mb-lg-0">
            <h6 className="fs-16 mb-0">Showing {sortedCandidates.length} results</h6>
          </div>
        </Col>
        <Col lg={4}>
          <div className="candidate-list-widgets">
            <Row>
              <Col lg={6}>
                <div className="selection-widget">
                  <select
                    className="form-select"
                    data-trigger
                    onChange={(e) => setSortOrder(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="df">Default</option>
                    <option value="ne">Most Accurate</option>
                    <option value="od">Least Accurate</option>
                  </select>
                </div>
              </Col>
              <Col lg={6}>
                <div className="selection-widget mt-2 mt-lg-0">
                  <select
                    className="form-select"
                    data-trigger
                    onChange={(e) => {
                      setItemsPerPage(Number(e.target.value));
                      setCurrentPage(0);
                    }}
                    aria-label="Default select example"
                  >
                    <option value={8}>8 per Page</option>
                    <option value={12}>12 per Page</option>
                    <option value={candidates.length}>All</option>
                  </select>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          {sortedCandidates.length > 0 && (
            <>
              <div className="candidate-list-box card mt-4">
                <CardBody><div className="candidate-list">
                  <h6 className="fs-14 mb-2">Product Category</h6>
                  <span className="badge bg-primary">{category}</span>
                </div>
                </CardBody>
              </div>
              <div className="candidate-list-box card mt-4">
                <CardBody>
                  <div className="candidate-list">
                    <h6 className="fs-14 mb-2">HS Code Match % Rate</h6>
                    {sortedCandidates.length > 0 ? (
                      sortedCandidates.map((candidate, index) => (
                        <div key={index} className="clickable-candidate" onClick={() => {
                          const pageToNavigate = Math.floor(index / itemsPerPage);
                          setCurrentPage(pageToNavigate);
                          handlePageClick({ selected: pageToNavigate });
                          setTimeout(() => scrollToElement(candidate.code), 400);
                        }}>
                          <CardBody>
                            <Row>
                              <Col lg={8}>
                                <button
                                  className="btn btn-link p-0 text-start w-100"
                                >
                                  {candidate.code}
                                </button>
                                <ExpandText text={candidate.desc} maxLength={27} />
                              </Col>
                              <Col lg={4}>
                                {candidate.accuracyRating === "0%" ? null : (
                                  <span
                                    style={{ fontSize: "0.8rem" }}
                                    className={
                                      parseFloat(candidate.accuracyRating) > 90
                                        ? "bg-info-subtle text-info rounded p-1 float-end"
                                        : parseFloat(candidate.accuracyRating) > 80
                                          ? "bg-success-subtle text-success rounded p-1 float-end"
                                          : parseFloat(candidate.accuracyRating) > 50
                                            ? "bg-warning-subtle text-warning rounded p-1 float-end"
                                            : "bg-danger-subtle text-danger rounded p-1 float-end"
                                    }
                                  >
                                    {candidate.accuracyRating}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                          <hr />
                        </div>
                      ))
                    ) : (null
                    )}
                  </div>
                </CardBody>
              </div>
            </>
          )}
        </Col>
        <Col lg="9" >
          <div className="candidate-list">
            {(currentCandidates || []).map((candidateDetailsNew, key) => (
              <div key={key} className="candidate-list-box card mt-4" id={candidateDetailsNew.code}>
                <CardBody className="p-4">
                  <Row className="align-items-center">
                    <Col lg={1}>
                      <div className="candidate-list-images">
                        <img
                          src={candidateDetailsNew.country_flag_url}
                          alt=""
                          className="img-thumbnail"
                        />
                      </div>
                    </Col>
                    <Col lg={10}>
                      <div className="candidate-list-content mt-3 mt-lg-0">
                        <h5 className="fs-19 mb-0">
                          <Link to={`/hs-code-details?product=${candidateDetailsNew.code}&destination=${metadata.destination}&origin=${metadata.origin}`} className="primary-link">
                            {candidateDetailsNew.code}
                          </Link>{" "}
                          {candidateDetailsNew.accuracyRating === "0%" ? null : (
                            <span
                              className={
                                parseFloat(candidateDetailsNew.accuracyRating) > 90
                                  ? "badge bg-info"
                                  : parseFloat(candidateDetailsNew.accuracyRating) > 80
                                    ? "badge bg-success"
                                    : parseFloat(candidateDetailsNew.accuracyRating) > 50
                                      ? "badge bg-warning"
                                      : "badge bg-danger"
                              }
                            >
                              <i className="mdi mdi-star align-middle"></i>
                              {candidateDetailsNew.accuracyRating}
                            </span>
                          )}
                        </h5>
                        <p className="text-muted mb-2">
                          <i className="mdi mdi-map-marker"></i>{" "}
                          {countryName(metadata.destination)}
                        </p>
                        <ul className="list-inline mb-0 text-muted">
                          <li className="list-inline-item">
                            <p>
                              <b>Description:</b> <span dangerouslySetInnerHTML={{ __html: candidateDetailsNew.desc }} />
                            </p>
                          </li>
                        </ul>
                        <div className="availability-badges">
                          {/* Show skeleton if availability not yet fetched */}
                          {availability[candidateDetailsNew.code] ? (
                            <>
                              {availability[candidateDetailsNew.code].importDuty && (
                                <span style={{ fontSize: "0.8rem" }} className="bg-primary-subtle text-primary me-1 rounded p-1">Import Duty</span>
                              )}
                              {availability[candidateDetailsNew.code].qr && (
                                <span style={{ fontSize: "0.8rem" }} className="bg-success-subtle text-success me-1 rounded p-1">Import Export Restriction</span>
                              )}
                              {availability[candidateDetailsNew.code].antiDumping && (
                                <span style={{ fontSize: "0.8rem" }} className="bg-warning-subtle text-warning me-1 rounded p-1">Anti-Dumping</span>
                              )}
                              {availability[candidateDetailsNew.code].pr && (
                                <span style={{ fontSize: "0.8rem" }} className="bg-info-subtle text-info me-1 rounded p-1">Product Requirement</span>
                              )}
                              {availability[candidateDetailsNew.code].importLicensing && (
                                <span style={{ fontSize: "0.8rem" }} className="bg-danger-subtle text-danger me-1 rounded p-1">Import Licensing</span>
                              )}
                              {availability[candidateDetailsNew.code].euTaric && (
                                <span style={{ fontSize: "0.8rem" }} className="bg-dark-subtle text-dark me-1 rounded p-1">EU Commission</span>
                              )}
                            </>
                          ) : (
                            <React.Fragment>
                              <Skeleton width={Math.floor(Math.random() * (100 - 50 + 1) + 50)} height={20} />
                              <Skeleton width={Math.floor(Math.random() * (100 - 50 + 1) + 50)} height={20} />
                              <Skeleton width={Math.floor(Math.random() * (100 - 50 + 1) + 50)} height={20} />
                              <Skeleton width={Math.floor(Math.random() * (100 - 50 + 1) + 50)} height={20} />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={1}>
                      <div className="mt-2 mt-lg-0">
                        <Link
                          to={`/hs-code-details?product=${candidateDetailsNew.code}&destination=${metadata.destination}&origin=${metadata.origin}`}
                          className="btn btn-primary float-end"
                          rel="noopener noreferrer"
                        >
                          <i className="mdi mdi-eye"></i>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </div>
            ))}

          </div>

          {pageCount >= 1 && (
            <div className="pagination-controls mt-5 d-flex justify-content-center">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName="active"
                nextClassName="page-item"
                previousClassName="page-item"
                nextLinkClassName="page-link"
                previousLinkClassName="page-link"
              />
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CandidateDetails;
