import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import CountryOptions from "../../Home/SubSection/CountryOptions";
import JobSearch from "../../Home/SubSection/JobSearch";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JobFilters = ({ initialProduct = "", initialDestination = null, initialOrigin = null }) => {
  const [product, setProduct] = useState(initialProduct); 
  const [destination, setDestination] = useState(null); // Changed to null initially
  const [origin, setOrigin] = useState(null); // Changed to null initially
  const [countryOptions, setCountryOptions] = useState([]); // To store country options
  const navigate = useNavigate();

  // When country options are fetched, set the initial destination and origin
  useEffect(() => {
    if (countryOptions.length > 0) {
      if (initialDestination) {
        const foundDestination = countryOptions.find(opt => opt.value === initialDestination);
        setDestination(foundDestination || null); // Set to object or null if not found
      }
      if (initialOrigin) {
        const foundOrigin = countryOptions.find(opt => opt.value === initialOrigin);
        setOrigin(foundOrigin || null); // Set to object or null if not found
      }
    }
  }, [countryOptions, initialDestination, initialOrigin]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (product && destination?.value && origin?.value) {
      const searchParams = `/hs-code-search?product=${encodeURIComponent(product)}&destination=${destination.value}&origin=${origin.value}`;

      if (window.location.pathname === '/hs-code-search') {
        navigate(searchParams);
      } else {
        window.location.href = searchParams;
      }
    } else {
      toast.error("Please fill in all fields!");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col lg={12}>
          <div className="candidate-list-widgets mb-4">
            <Form onSubmit={handleSubmit}>
              <div className="registration-form">
                <Row className="g-0">
                  <Col md={12}>
                    <div className="filter-search-form filter-border mt-1 mb-2 mt-md-0">
                      <i className="uil uil-briefcase-alt"></i>
                      <JobSearch
                        value={product} 
                        onChange={(e) => setProduct(e.target.value)} 
                      />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="filter-search-form mt-3 mt-md-0">
                      <i className="uil uil-map-marker"></i>
                      <CountryOptions 
                        onCountrySelect={setDestination} 
                        selectedCountry={destination} 
                        setCountryOptions={setCountryOptions} // Pass down the setter
                        label={"Destination Country"}
                      />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="filter-search-form mt-3 mt-md-0">
                      <i className="uil uil-map-marker"></i>
                      <CountryOptions 
                        onCountrySelect={setOrigin} 
                        selectedCountry={origin} 
                        setCountryOptions={setCountryOptions} // Pass down the setter
                        label={"Origin Country"}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mt-3 mt-md-0 h-100">
                      <button className="btn btn-primary submit-btn w-100 h-100" type="submit">
                        <i className="uil uil-search me-1"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default JobFilters;
