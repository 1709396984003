import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import Link from "Utils/LinkWrapper";
import { ConditionalWrapper, countryName, findCountriesInData } from "Utils/Utils";
import BlurRandomTextWrapper from "Utils/BlurRandomTextWrapper";
import { useAuth } from "Utils/AuthContext";
import { Regulation } from "./Regulation";
import { MeasureItem } from "./MeasureItem";
import VATComponent from "pages/Jobs/JobDetails/VAT";

function parseDate(dateStr) {
  const standardizedDate = dateStr.replace(/\//g, '-');

  const isDDMMYYYY = standardizedDate.match(/^\d{2}-\d{2}-\d{4}$/);
  if (isDDMMYYYY) {
    const [day, month, year] = standardizedDate.split('-');
    return new Date(`${year}-${month}-${day}T00:00:00`);
  }

  return new Date(standardizedDate);
}

const RightSideContent = ({ data, hasBurnedCredit }) => {
  const { isFetchingCredit, isAuthLoading } = useAuth();

  const importDutyData = data?.importDuty?.FTAImportDuty?.find((item, index) =>
    index === 0 || item.find((subItem) => subItem.HSCode.slice(0, 6) === data?.hs?.data?.six_digit_codes[0]?.searchTerm.slice(0, 6))
  );

  const {
    antidumping_measures,
    antidumping_investigations,
    countervailing_measures,
    countervailing_investigations,
  } = data?.antidumping || {};

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="candidate-details ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4 candidate-personal-detail">
            {/* HS Code Description */}
            <div>
              <h6 className="fs-17 fw-semibold mb-3">HS Code Description </h6>
              <p className="text-muted mb-2">
                <b>{data?.hs?.data?.six_digit_codes[0]?.code2 || <Skeleton width={100} />} :</b>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.hs?.data?.six_digit_codes[0]?.name2 || '',
                  }}
                />
                {!data?.hs?.data?.six_digit_codes[0]?.name2 && <Skeleton width={500} />}
              </p>
              <p className="text-muted mb-2">
                <b>{data?.hs?.data?.six_digit_codes[0]?.code4 || <Skeleton width={100} />} :</b>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.hs?.data?.six_digit_codes[0]?.name4 || '',
                  }}
                />
                {!data?.hs?.data?.six_digit_codes[0]?.name4 && <Skeleton width={500} />}
              </p>
              <p className="text-muted mb-2">
                <b>{data?.hs?.data?.six_digit_codes[0]?.code6 || <Skeleton width={100} />} :</b>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.hs?.data?.six_digit_codes[0]?.name6 || '',
                  }}
                />
                {!data?.hs?.data?.six_digit_codes[0]?.name6 && <Skeleton width={500} />}
              </p>
              <p className="text-muted mb-2">
                <b>
                  {data?.hs?.data?.national_tariff_lines.find(
                    ({ code }) => code === data?.hs?.data?.six_digit_codes[0]?.searchTerm
                  )?.code || <Skeleton width={100} />} :
                </b>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.hs?.data?.national_tariff_lines.find(
                        ({ code }) => code === data?.hs?.data?.six_digit_codes[0]?.searchTerm
                      )?.desc || '',
                  }}
                />
                {!data?.hs?.data?.national_tariff_lines.find(
                  ({ code }) => code === data?.hs?.data?.six_digit_codes[0]?.searchTerm
                )?.desc && <Skeleton width={500} />}
              </p>
              <p className="text-muted mb-0">
                <b>
                  {data?.hs?.data?.trans_national_tariff_lines.find(
                    ({ code }) => code === data?.hs?.data?.six_digit_codes[0]?.searchTerm
                  )?.code || <Skeleton width={100} />} (English Translated) :
                </b>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.hs?.data?.trans_national_tariff_lines.find(
                        ({ code }) => code === data?.hs?.data?.six_digit_codes[0]?.searchTerm
                      )?.desc || '',
                  }}
                />
                {!data?.hs?.data?.trans_national_tariff_lines.find(
                  ({ code }) => code === data?.hs?.data?.six_digit_codes[0]?.searchTerm
                )?.desc && <Skeleton width={500} />}
              </p>
            </div>

            {isFetchingCredit || isAuthLoading ? (
              <>
                <br />
                <Skeleton width={600} height={20} />
                <Skeleton width={400} height={20} />
                <Skeleton width={300} height={20} />
              </>
            ) : (
              <ConditionalWrapper
                condition={hasBurnedCredit}
                wrapper={(children) => <BlurRandomTextWrapper>{children}</BlurRandomTextWrapper>}
              >
                {/* VAT Details */}
                <div className="candidate-education-details mt-4 pt-3">
                  <h6 id="vat" className="fs-17 fw-bold mb-0">VAT Rate</h6>
                  {!data?.vat && (
                    <div className="candidate-education-content mt-4 d-flex">
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        <i className="uil uil-percentage"></i>
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1"><Skeleton width={120} /></h6>
                        <p className="mb-2 text-muted"><Skeleton width={150} /></p>
                      </div>
                    </div>
                  )}
                  {data?.vat && <VATComponent data={data} />}
                </div>

                {/* Import Duty Details */}
                <div className="candidate-education-details mt-4 pt-3">
                  <h6 id="import-duty" className="fs-17 fw-bold mb-0">Import Duty Details</h6>
                  {data?.metadata?.destination === '918' || data?.metadata?.destination === '899' ? (
                    <div className="candidate-education-content mt-4 d-flex">
                    <p>For Import Duty, please select a Country Name in the search.</p>
                    </div>
                  ) : null}
                  {!data?.importDuty?.FTAImportDuty && (
                    <div className="candidate-education-content mt-4 d-flex">
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        <i className="uil uil-percentage"></i>
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1"><Skeleton width={120} /></h6>
                        <p className="mb-2 text-muted"><Skeleton width={150} /></p>
                      </div>
                    </div>
                  )}
                  {importDutyData &&
                    importDutyData
                      .flatMap((item) =>
                        item.ImportDutyData.map((dutyItem) => ({
                          ftaName: item.FTANameFull || item.FTAName,
                          rate: dutyItem.ImportDutyRate === "T" ? "N/A" : dutyItem.ImportDutyRate || "N/A",
                          type: dutyItem.ImportDutyType || item.AgreementData.Type,
                          product: data.metadata.product,
                          destination: data.metadata.destination,
                          origin: data.metadata.origin,
                        }))
                      )
                      .sort((a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()))
                      .map((item, index) => (
                        <div key={index} id={item.ftaName} className="candidate-education-content mt-4 d-flex">
                          <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                            <i className="uil uil-percentage"></i>
                          </div>
                          <div className="ms-4">
                            <h6 className="fs-16 mb-1">
                              {item.type} : <b>{item.rate}</b>
                            </h6>
                            <p className="mb-2 text-muted">
                              <Link to={`/regulation-details?id=${encodeURIComponent(item.ftaName)}&type=Import Duty&product=${encodeURIComponent(item.product)}&destination=${encodeURIComponent(item.destination)}&origin=${encodeURIComponent(item.origin)}`}>
                                {item.ftaName || <Skeleton width={150} />}
                              </Link>
                            </p>
                          </div>
                        </div>
                      ))}

                </div>

                {/* Antidumping / Countervailing Rate Detail */}
                <div className="candidate-education-details mt-4 pt-3">
                  <h6 className="fs-17 fw-bold mb-0">Antidumping / Countervailing Rate</h6>

                  {!data?.antidumping && (
                    <div className="candidate-education-content mt-4 d-flex">
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        <i className="uil uil-percentage"></i>
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1"><Skeleton width={120} /></h6>
                        <p className="mb-0 text-muted"><Skeleton width={180} /></p>
                        <p className="mb-0 text-muted"><Skeleton width={150} /></p>
                        <p className="mb-0 text-muted"><Skeleton width={100} /></p>
                        <p className="mb-0 text-muted"><b><Skeleton width={130} /></b></p>
                        <p className="mb-0 text-muted"><b><Skeleton width={160} /></b></p>
                      </div>
                    </div>
                  )}

                  {/* Render Antidumping Measures */}
                  {(antidumping_measures?.length === 0) &&
                    (antidumping_investigations?.length === 0) &&
                    (countervailing_measures?.length === 0) &&
                    (countervailing_investigations?.length === 0) ? (
                    <div className="candidate-education-content mt-4 d-flex">
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        <i className="uil uil-percentage"></i>
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1">N/A</h6>
                        <p>No data found</p>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      {(() => {
                        const mergedMeasures = [
                          ...(antidumping_measures || []).map(item => ({
                            title: "Antidumping Measures",
                            item,
                            citedCountry: findCountriesInData(item),
                          })),
                          ...(antidumping_investigations || []).map(item => ({
                            title: "Antidumping Investigations",
                            item,
                            citedCountry: findCountriesInData(item),
                          })),
                          ...(countervailing_measures || []).map(item => ({
                            title: "Countervailing Measures",
                            item,
                            citedCountry: findCountriesInData(item),
                          })),
                          ...(countervailing_investigations || []).map(item => ({
                            title: "Countervailing Investigations",
                            item,
                            citedCountry: findCountriesInData(item),
                          })),
                        ];

                        // if countryName(data.metadata.origin) is in citedCountry, prioritize this data to show first
                        if (mergedMeasures.length > 0) {
                          mergedMeasures.sort((a, b) => {
                            const aCountry = a.citedCountry.find(country => country === countryName(data.metadata.origin));
                            const bCountry = b.citedCountry.find(country => country === countryName(data.metadata.origin));
                            return aCountry ? bCountry ? 0 : -1 : bCountry ? 1 : 0;
                          });
                        }

                        return mergedMeasures.map((measure, index) => (
                          <MeasureItem
                            key={index}
                            title={measure.title}
                            item={measure.item}
                            data={data}
                            citedCountry={measure.citedCountry}
                          />
                        ));
                      })()}
                    </React.Fragment>
                  )}
                </div>

                {/* Regulations Detail */}
                <div className="candidate-education-details mt-4 pt-3">
                  <h6 id="regulations" className="fs-17 fw-bold mb-0">Regulations</h6>

                  {!data?.qr?.data || !data?.pr?.ProductRequirementsDetails || !data?.importLicensing?.data ? (

                    <div className="candidate-education-content mt-4 d-flex">
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        <i className="uil uil-exclamation-triangle"></i>
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1"><Skeleton width={120} /></h6>
                        <p className="mb-0 text-muted"><Skeleton width={150} /></p>
                        <p className="mb-0 text-muted"><Skeleton width={100} /></p>
                      </div>
                    </div>
                  ) : null}

                  {(data.qr?.data?.length === 0 && data.importLicensing?.data?.length === 0 && data.pr?.ProductRequirementsDetails?.length === 0 && data?.euTaric?.length === 0) ? (
                    <div className="candidate-education-content mt-4 d-flex">
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        <i className="uil uil-exclamation-triangle"></i>
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1">N/A</h6>
                        <p className="mb-0 text-muted">No data found</p>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      {(() => {
                        const uniqueItems = new Set();

                        const mergedData = [
                          ...(data.qr?.data || []).map(item => ({
                            title: item.general_description,
                            type: "Import Export Restriction",
                            date: item.in_force_from,
                            dateType: "In Force From",
                            id: item.id,
                            hs: item.affected_products.map(code => code.code).join(", "),
                            citedCountry: findCountriesInData(item),
                          })),
                          ...(data.qr?.dataOrigin || []).map(item => ({
                            title: item.general_description,
                            type: "Import Export Restriction",
                            date: item.in_force_from,
                            dateType: "In Force From",
                            id: item.id,
                            hs: item.affected_products.map(code => code.code).join(", "),
                            citedCountry: findCountriesInData(item),
                            export: true,
                          })),
                          ...(data.importLicensing?.data || []).map(item => ({
                            title: item.title,
                            type: "Import Licensing",
                            date: item.legislation_detail.LatestPublished,
                            dateType: "Latest Published",
                            id: item.title,
                            hs: item.mtechHSCode,
                            citedCountry: findCountriesInData(item),
                          })),
                          ...(data.pr?.ProductRequirementsDetails || []).map(item => ({
                            title: item.RequirementTitle,
                            type: "Product Requirement",
                            date: new Intl.DateTimeFormat('en-GB').format(new Date(item.distributionDate)),
                            dateType: "Distribution Date",
                            id: item.documentSymbol,
                            hs: item.hsCodes.map(code => code.HSCode).join(", "),
                            citedCountry: findCountriesInData(item),
                          })),
                          ...(data.pr?.ProductRequirementsDetailsOrigin || []).map(item => ({
                            title: item.RequirementTitle,
                            type: "Product Requirement",
                            date: new Intl.DateTimeFormat('en-GB').format(new Date(item.distributionDate)),
                            dateType: "Distribution Date",
                            id: item.documentSymbol,
                            hs: item.hsCodes.map(code => code.HSCode).join(", "),
                            citedCountry: findCountriesInData(item),
                            export: true
                          })),
                          ...(data.euTaric?.[0]?.measures_details || []).map(item => ({
                            title: item.description,
                            type: "EU Commission",
                            date: data.euTaric?.[0]?.last_update,
                            dateType: "Last Update",
                            id: data.euTaric?.[0]?.nomenclature_code,
                            hs: data.euTaric?.[0]?.nomenclature_code,
                            citedCountry: [],
                          })),
                          ...(data.docs?.data || []).map(item => ({
                            title: item.regulation_title?.replace('_notification', ''),
                            type: "Legislation",
                            date: new Intl.DateTimeFormat('en-GB').format(new Date(item.document_date)),
                            dateType: "Document Date",
                            id: item.id,
                            citedCountry: findCountriesInData(item),
                            export: true
                          })),
                        ]
                          .filter(item => {
                            if (uniqueItems.has(item.id)) {
                              return false;
                            }
                            uniqueItems.add(item.id);
                            return true;
                          });

                        let mergedDataSorted = [...mergedData].sort((a, b) => {
                          const dateA = parseDate(a.date);
                          const dateB = parseDate(b.date);

                          return dateB - dateA;
                        });

                        // if countryName(data.metadata.origin) is in citedCountry, prioritize this data to show first while still priorizing newest date
                        if (mergedDataSorted.length > 0) {
                          mergedDataSorted.sort((a, b) => {
                            const aCountry = a.citedCountry.find(country => country === countryName(data.metadata.origin));
                            const bCountry = b.citedCountry.find(country => country === countryName(data.metadata.origin));
                            return aCountry ? bCountry ? 0 : -1 : bCountry ? 1 : 0;
                          });
                        }

                        return mergedDataSorted.map((item, index) => (
                          <Regulation
                            key={index}
                            title={item.title}
                            type={item.type}
                            date={item.date}
                            date_type={item.dateType}
                            data={data}
                            id={item.id}
                            hs={item.hs}
                            citedCountry={item.citedCountry}
                            noLink={true}
                            is_export={item?.export}
                          />
                        ));
                      })()}
                    </React.Fragment>
                  )}
                </div>
              </ConditionalWrapper>
            )}

          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
