import ExpandText from "Utils/ExpandText";

const { Accordion } = require("react-bootstrap");

function buildHierarchy(sections) {
  const root = [];

  sections.forEach((section) => {
    const parts = section.section_title
      .split(";")
      .map((part) => part.trim())
      .filter((part) => part !== "…" && part !== "");

    let currentLevel = root;

    parts.forEach((part, index) => {
      let existing = currentLevel.find((item) => item.title === part);
      if (!existing) {
        existing = { title: part, children: [], id: section.id };
        currentLevel.push(existing);

        currentLevel.sort((a, b) => a.id - b.id);
      }

      if (index === parts.length - 1) {
        existing.referenceId = section.section_title.replace(/\s+/g, "-").toLowerCase();
        existing.content = section.section_content;
      }

      currentLevel = existing.children;
    });
  });

  return root;
}

function renderAccordion(items, parentKey = "0") {
  return items.map((item, index) => {
    const key = `${parentKey}-${index}`;

    if (item.children.length === 0) {
      return (
        <div key={key} style={{ paddingLeft: "1rem", margin: "0.5rem 0" }}>
          <button
            onClick={() => {
              document.querySelector("#pills-tab > li:nth-child(2) > a").click();
              setTimeout(() => {
                scrollToElement(item.referenceId);
              }, 200);
            }}
            style={{
              background: "none",
              border: "none",
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            <ExpandText text={item.title} maxLength={27} />
          </button>
        </div>
      );
    }

    return (
      <Accordion.Item eventKey={key} key={key} >
        <Accordion.Header>{item.title}</Accordion.Header>
        <Accordion.Body>
          <Accordion defaultActiveKey="0">{renderAccordion(item.children, key)}</Accordion>
        </Accordion.Body>
      </Accordion.Item>
    );
  });
}

function renderContentSections(hierarchy) {
  const sections = [];

  function traverse(items) {
    items.forEach((item) => {
      if (item.content && item.referenceId) {
        const normalizedContent = item.content.replace(/\n\s*\n\s*\n+/g, "\n\n");

        if (item.title === "Full Page") {
          sections.push(
            <section id={item.referenceId} key={item.referenceId}>
              <iframe
                srcDoc={normalizedContent}
                style={{ width: '100%', border: 'none' }}
                title={item.title}
                onLoad={(e) => {
                  const iframe = e.target;
                  const iframeDocument = iframe.contentWindow.document;

                  const links = iframeDocument.querySelectorAll('a');
                  links.forEach(link => {
                    link.addEventListener('click', (event) => event.preventDefault());
                  });

                  iframe.style.height = iframeDocument.documentElement.scrollHeight + "px";
                }}
              />
            </section>
          );
        } else {
          sections.push(
            <section id={item.referenceId} key={item.referenceId}>
              <h4>{item.title}</h4>
              <pre style={{ whiteSpace: "pre-line" }}>{normalizedContent}</pre>
            </section>
          );
        }
      }
      if (item.children.length > 0) {
        traverse(item.children);
      }
    });
  }

  traverse(hierarchy);
  return sections;
}

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const elementRect = element.getBoundingClientRect();
    const elementOffset = elementRect.top + window.scrollY - 100;

    window.scrollTo({
      top: elementOffset,
      behavior: "smooth",
    });
  }
};

export { buildHierarchy, renderAccordion, renderContentSections };