import React, { useRef, useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

const FindComponent = ({tabId}) => {
    const inputRef = useRef(null);
    const [findMessage, setFindMessage] = useState(null);

    useEffect(() => {
        const findMsgElement = document.getElementById('find_msg');
        if (findMsgElement) {
            const observer = new MutationObserver(() => {
                setFindMessage(findMsgElement.textContent.replace(' of ', '/'));
            });
            observer.observe(findMsgElement, { childList: true, subtree: true });
            return () => observer.disconnect();
        }
    }, []);

    const handleFind = () => {
        const findButton = document.querySelector('input[type="button"][value=" > "]');
        if (findButton) {
            findButton.click();
        }
    };

    const handleFindPrev = () => {
        const findPrevButton = document.querySelector('input[type="button"][value=" < "]');
        if (findPrevButton) {
            findPrevButton.click();
        }
    };

    const handleInputChange = (event) => {
        const findInput = document.getElementById('fwtext');
        if (findInput) {
            // document.querySelector(`#pills-tab > li:nth-child(${tabId}) > a`).click();
            findInput.value = event.target.value;
            findInput.dispatchEvent(new Event('change', { bubbles: true }));
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleFind();
        }
    };

    return (
        <div className="find-component mb-3 sticky">
            <InputGroup className="d-flex" style={{ flexWrap: 'nowrap' }}>
                <Input
                    type="search"
                    placeholder="Find in this page..."
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                />
                {findMessage && <InputGroupText style={{ backgroundColor: 'transparent' }}>{findMessage}</InputGroupText>}
                <InputGroupText onClick={handleFindPrev} style={{ cursor: 'pointer' }}>
                    <i className="mdi mdi-chevron-up"></i>
                </InputGroupText>
                <InputGroupText onClick={handleFind} style={{ cursor: 'pointer' }}>
                    <i className="mdi mdi-chevron-down"></i>
                </InputGroupText>
            </InputGroup>
        </div>
    );
};

export default FindComponent;
