/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { countryCode, countryFlag, countryName } from "../../../Utils/Utils";
import ExpandText from "../../../Utils/ExpandText";

const processImportDutyData = (data) => {
  if (!data) return [];

  const dutySet = new Set();

  return data.map(item => {
    const uniqueDuties = item.ImportDutyData?.filter(duty => {
      const dutyString = `${duty.ImportDutyRate} (${duty.ImportDutyType})`;
      if (dutySet.has(dutyString)) {
        return false; // skip duplicates
      }
      dutySet.add(dutyString);
      return true;
    }) || [];

    return uniqueDuties.map(duty => `${duty.ImportDutyRate} (${duty.ImportDutyType})`).join(', ') || "";
  });
};

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const elementRect = element.getBoundingClientRect();
    const elementOffset = elementRect.top + window.scrollY;
    const offset = window.innerHeight / 2 - elementRect.height / 2;

    window.scrollTo({
      top: elementOffset - offset,
      behavior: 'smooth'
    });

    const originalStyle = { ...element.style };
    element.style.transition = 'background-color 0.5s';
    element.style.backgroundColor = 'rgba(189, 186, 231, 0.5)';
    const timeout = setTimeout(() => {
      element.style.transition = 'background-color 0.5s';
      element.style.backgroundColor = originalStyle.backgroundColor || '';
    }, 1000);
    const originalOnClick = element.onclick;
    element.onclick = () => {
      clearTimeout(timeout);
      element.onclick = originalOnClick;
      element.style.backgroundColor = originalStyle.backgroundColor || '';
    };
  }
};

const RegulationOverview = ({ data, importDutyData }) => {
  return (
    <React.Fragment>
      <CardBody className="p-4 border-top z-3">
        <h6 className="fs-17 fw-medium mb-3">Regulations Overview</h6>
        <div className="d-flex flex-wrap align-items-start gap-1">
          {!data?.importDuty?.FTAImportDuty && (
            <Skeleton width={150} />
          )}
          {importDutyData && importDutyData.map((item, index) => {
            const ftaName = item.FTANameFull || item.FTAName;
            return (
              <div
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  scrollToElement(ftaName);
                }}
              >
                <ExpandText
                  maxLength={35}
                  className="badge bg-success-subtle text-success fs-13 mt-1"
                  text={ftaName || <Skeleton width={150} />}
                />
              </div>
            );
          })}

          {!data?.qr?.data && <Skeleton width={150} />}
          {data.qr && data.qr.data.map((item, index) => {
            const restrictionName = item.general_description;
            return (
              <div
                key={index} // Move the key here
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event propagation
                  scrollToElement(restrictionName);
                }}
              >
                <ExpandText
                  maxLength={35}
                  className="badge bg-danger-subtle text-danger fs-13 mt-1"
                  text={restrictionName || <Skeleton width={150} />}
                />
              </div>
            );
          })}

          {!data?.importLicensing?.data && <Skeleton width={150} />}
          {data.importLicensing && data.importLicensing.data.map((item, index) => {
            const restrictionName = item.title;
            return (
              <div
                key={index} // Move the key here
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event propagation
                  scrollToElement(restrictionName);
                }}
              >
                <ExpandText
                  maxLength={35}
                  className="badge bg-primary-subtle text-primary fs-13 mt-1"
                  text={restrictionName || <Skeleton width={150} />}
                />
              </div>
            );
          })}

          {!data?.pr?.ProductRequirementsDetailsOrigin && <Skeleton width={150} />}
          {data.pr && (() => {
            const seenRestrictions = new Set();
            return data.pr.ProductRequirementsDetailsOrigin.map((item, index) => {
              const restrictionName = item.RequirementTitle;

              if (!restrictionName || seenRestrictions.has(restrictionName)) {
                return null;
              }

              seenRestrictions.add(restrictionName);

              return (
                <div
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    scrollToElement(restrictionName);
                  }}
                >
                  <ExpandText
                    maxLength={35}
                    className="badge bg-info-subtle text-info fs-13 mt-1"
                    text={restrictionName || <Skeleton width={150} />}
                  />
                </div>
              );
            });
          })()}

          {!data?.pr?.ProductRequirementsDetails && <Skeleton width={150} />}
          {data.pr && (() => {
            const seenRestrictions = new Set();
            return data.pr.ProductRequirementsDetails.map((item, index) => {
              const restrictionName = item.RequirementTitle;

              if (!restrictionName || seenRestrictions.has(restrictionName)) {
                return null;
              }

              seenRestrictions.add(restrictionName);

              return (
                <div
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    scrollToElement(restrictionName);
                  }}
                >
                  <ExpandText
                    maxLength={35}
                    className="badge bg-info-subtle text-info fs-13 mt-1"
                    text={restrictionName || <Skeleton width={150} />}
                  />
                </div>
              );
            });
          })()}


          {data.euTaric && data.euTaric?.[0]?.measures_details.map((item, index) => {
            const restrictionName = item.description;
            return (
              <div
                key={index} // Move the key here
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event propagation
                  scrollToElement(restrictionName);
                }}
              >
                <ExpandText
                  maxLength={35}
                  className="badge bg-dark-subtle text-dark fs-13 mt-1"
                  text={restrictionName}
                />
              </div>
            );
          })}
        </div>
      </CardBody>

      <CardBody className="candidate-contact-details p-4 border-top">
        <h6 className="fs-17 fw-medium mb-3">Import Duty Details</h6>
        <ul className="list-unstyled mb-0">
          {data?.metadata?.destination === '918' || data?.metadata?.destination === '899' ? (
            <p>For Import Duty, please select a Country Name in the search.</p>
          ) : null}
          {!importDutyData ? (
            <li>
              <Skeleton width={210} />
              <Skeleton width={180} />
              <Skeleton width={150} />
              <Skeleton width={100} />
            </li>
          ) : (
            importDutyData?.map((item, index) => (
              <React.Fragment key={index}>
                <li>
                  <div className="d-flex align-items-center mt-4">
                    <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                      <i className="uil uil-file-contract"></i>
                    </div>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Agreement Name Full</h6>
                      <p className="text-muted mb-0">{item.FTANameFull || item.FTAName || <Skeleton width={150} />}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center mt-4">
                    <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                      <i className="uil uil-percentage"></i>
                    </div>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Import Duty Rate</h6>
                      <div>
                        <p className="text-muted mb-0">
                          MFN: {item.ImportDutyData?.filter(duty => duty.ImportDutyType === 'MFN').reduce((prev, current) => prev?.ImportDutyRate > current?.ImportDutyRate ? prev : current, { ImportDutyRate: 0 })?.ImportDutyRate === "T" ? "N/A" : item.ImportDutyData?.filter(duty => duty.ImportDutyType === 'MFN').reduce((prev, current) => prev?.ImportDutyRate > current?.ImportDutyRate ? prev : current, { ImportDutyRate: 0 })?.ImportDutyRate || "N/A"}
                        </p>
                        <p className="text-muted mb-0">
                          Preferential: {item.ImportDutyData?.filter(duty => duty.ImportDutyType === 'Preferential').reduce((prev, current) => prev?.ImportDutyRate > current?.ImportDutyRate ? prev : current, { ImportDutyRate: "N/A" })?.ImportDutyRate !== undefined ? item.ImportDutyData?.filter(duty => duty.ImportDutyType === 'Preferential').reduce((prev, current) => prev?.ImportDutyRate > current?.ImportDutyRate ? prev : current, { ImportDutyRate: "N/A" })?.ImportDutyRate : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                {item.AgreementData?.InforceDate && (
                  <li>
                    <div className="d-flex align-items-center mt-4">
                      <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                        <i className="uil uil-calender"></i>
                      </div>
                      <div className="ms-3">
                        <h6 className="fs-14 mb-1">In Force Date</h6>
                        <p className="text-muted mb-0">{item.AgreementData?.InforceDate}</p>
                      </div>
                    </div>
                  </li>
                )}
                {item.AgreementData?.MemberStates && (
                  <li>
                    <div className="d-flex align-items-center mt-4">
                      <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                        <i className="uil uil-globe"></i>
                      </div>
                      <div className="ms-3">
                        <h6 className="fs-14 mb-1">Member States</h6>
                        <p className="text-muted mb-0">{item.AgreementData?.MemberStates}</p>
                      </div>
                    </div>
                  </li>
                )}
                <hr />
              </React.Fragment>
            ))
          )}
        </ul>
      </CardBody>
    </React.Fragment>
  )
}

const LeftSideContent = ({ data, hasBurnedCredit }) => {
  const importDutyData = data?.importDuty?.FTAImportDuty?.find((item, index) =>
    index === 0 || item.find((subItem) => subItem.HSCode.slice(0, 6) === data?.hs?.data?.six_digit_codes[0]?.searchTerm.slice(0, 6))
  );

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card className="side-bar">
          <CardBody className="p-4">
            <div className="candidate-profile text-center">
              {!data?.importDuty?.DestCountryName ? (
                <Skeleton width={80} height={54} />
              ) : (
                <img
                  src={countryFlag(countryName(countryCode(data?.importDuty?.DestCountryName)), 'w80')}
                  alt=""
                  className=""
                />
              )}
              <h6 className="fs-18 mb-2 mt-4">
                HS Code: {data?.hs?.data?.six_digit_codes[0]?.searchTerm || <Skeleton width={100} />}
              </h6>
              <p className="text-muted mb-1">
                Destination Country: <b>{data?.importDuty?.DestCountryName || <Skeleton width={150} />}</b>
              </p>
              <p className="text-muted mb-2">
                Partner/Affected Country: <b>{data?.importDuty?.OriginCountryName || <Skeleton width={150} />}</b>
              </p>
              <ul className="candidate-detail-social-menu list-inline mb-0">
                <li className="list-inline-item">
                  <a href="javascript:void(0);" onClick={(e) => {
                    e.stopPropagation();
                    scrollToElement("import-duty");
                  }} className="social-link">
                    <i className="uil uil-percentage"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript:void(0);" onClick={(e) => {
                    e.stopPropagation();
                    scrollToElement("regulations");
                  }} className="social-link">
                    <i className="uil uil-exclamation-triangle"></i>
                  </a>
                </li>
              </ul>

            </div>
          </CardBody>

          <CardBody className="candidate-profile-overview border-top p-4">
            <h6 className="fs-17 fw-medium mb-3">Results Summary</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <div className="d-flex">
                  <label className="text-dark">General Import Duty: </label>
                  <div>
                    <p className="text-muted mb-0">
                      &nbsp; {!importDutyData ? (
                        <Skeleton width={50} />
                      ) : (
                        processImportDutyData(importDutyData).map((dutyString) => (
                          dutyString ? `${dutyString}, ` : ''
                        ))
                      )}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Preferential Rate (FTA):</label>
                  <div>
                    <p className="text-muted mb-0">
                      &nbsp; {!data?.importDuty ? (
                        <Skeleton width={50} />
                      ) : (
                        importDutyData?.map(item => item?.ImportDutyData?.filter(duty => duty.ImportDutyType === 'Preferential')?.map(duty => duty.ImportDutyRate).join(', '))?.join(', ') || 'N/A'
                      )}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Anti-dumping Duty:</label>
                  <div>
                    <p className="text-muted mb-0">
                      &nbsp; {data?.antidumping ? (
                        data?.antidumping?.antidumping_investigations?.length === 0 &&
                          data?.antidumping?.antidumping_measures?.length === 0 &&
                          data?.antidumping?.countervailing_investigations?.length === 0 &&
                          data?.antidumping?.countervailing_measures?.length === 0 ? 'No' : 'Yes'
                      ) : <Skeleton width={50} />}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Import Export Restriction:</label>
                  <div>
                    <p className="text-muted mb-0">
                      &nbsp; {data?.qr ? (data?.qr?.data?.length === 0 ? 'No' : 'Yes') : <Skeleton width={50} />}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Product Requirements:</label>
                  <div>
                    <p className="text-muted mb-0">
                      &nbsp; {data?.pr ? (data?.pr?.totalCount === 0 ? 'N/A' : 'Yes') : <Skeleton width={50} />}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </CardBody>
          <RegulationOverview data={data} importDutyData={importDutyData} />
        </Card>
      </Col>
    </React.Fragment >
  );
};

export default LeftSideContent;
